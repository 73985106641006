<template>
  <DetailedPageContainer>
    <div class="services">
      <r-row class="px-4">
        <r-col :cols="{ widest: 4, wide: 4, middle: 6, narrow: 6 }">
          <EmergencyCard />
        </r-col>
      </r-row>

      <r-row class="services__title px-4">
        <r-col :cols="{ widest: 4, wide: 4, middle: 6, narrow: 6 }">
          <h2 class="camembert harakiri--text">
            {{ $t('services_contacts.title') }}
          </h2>
        </r-col>
      </r-row>

      <r-row class="px-4">
        <r-col v-for="(card, index) in GET_HOTLINES" :key="index" :cols="{ widest: 4, wide: 4, middle: 6, narrow: 6 }">
          <HotlineCard :card="card" />
        </r-col>
      </r-row>
    </div>
  </DetailedPageContainer>
</template>

<script>
import DetailedPageContainer from "@/components/Containers/DetailedPageContainer";
import HotlineCard from "@/components/DetailedViewParts/HotlineCard/HotlineCard";
import EmergencyCard from "@/components/DetailedViewParts/EmergencyCard/EmergencyCard";
import {mapGetters} from "vuex";

export default {
  name: "ServiceContactsScreen",

  components: {
    EmergencyCard,
    HotlineCard,
    DetailedPageContainer,
  },

  computed: {
    ...mapGetters(['GET_HOTLINES'])
  },
}
</script>

<style lang="scss">
.services {
  padding-bottom: 8px;

  &__title {
    margin-bottom: 24px;
  }
}
</style>
