<template>
  <div class="hotline-card">
    <r-row class="px-4">
      <r-col :cols="{ widest: 4, wide: 4, middle: 6, narrow: 5 }">
        <r-row gap-hidden>
          <r-col :cols="{ widest: 4, wide: 4, middle: 6, narrow: 6 }">
            <h6 class="bryndza harakiri--text">
              {{ card.title }}
            </h6>
            <p class="hotline-card__hours mozzarella metropolis--text" v-if="card.hours">
              {{ card.subtitle }}
            </p>
          </r-col>
        </r-row>

        <r-row class="hotline-card__hotlines" gap-hidden>
          <r-col v-for="(item, index) in card.phones" :key="index" :cols="{ widest: 4, wide: 4, middle: 6, narrow: 3 }">
            <span class="brie harakiri--text">
              {{ item.value }}
            </span>
            <div class="hotline-card__desc mozzarella metropolis--text" v-if="item.caption">
              {{ item.caption }}
            </div>
          </r-col>
        </r-row>
      </r-col>

      <r-col :cols="{ widest: 4, wide: 4, middle: 6, narrow: 1 }">
        <div class="hotline-card__icon" :class="[bgClass]">
          <r-icon :icon="card.type" fill="white"/>
        </div>
      </r-col>
    </r-row>
  </div>
</template>

<script>
export default {
  name: "HotlineCard",

  props: {
    card: {
      type: [Array, Object],
      default: () => []
    }
  },

  computed: {
    bgClass() {
      switch (this.card.type) {
        case 'police':
          return 'alien--bg';
        case 'home':
          return 'seven--bg';
        case 'services':
          return 'pulpfiction--bg';
        case 'partnership':
          return 'indiana--bg';
        case 'employee':
          return 'shershaah--bg';
        case 'park':
          return 'drive--bg';
        default:
          return '';
      }
    },
  }
}
</script>

<style lang="scss">
.hotline-card {
  min-height: 108px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: var(--rir-amelie);
  border-radius: 16px;
  padding: 24px 0;

  &__hours {
    margin-top: 4px;
  }

  &__icon {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    margin-left: auto;
  }

  &__hotlines {
    margin-top: 16px;
  }

  &__desc {
    margin-top: 4px;
  }
}
</style>
