<template>
  <div class="emergency-card">
    <r-row class="px-4">
      <r-col :cols="{ widest: 4, wide: 4, middle: 6, narrow: 4 }">
        <h3 class="emergency-card__title camembert">
          {{ $t('services_contacts.emergency.title') }}
        </h3>

        <p class="emergency-card__desc feta">
          {{ $t('services_contacts.emergency.desc') }}
        </p>
      </r-col>

      <r-col :cols="{ widest: 4, wide: 4, middle: 6, narrow: 2 }">
        <div class="emergency-card__img">
          <img src="/img/emergency/emergency.png" alt="">
        </div>
      </r-col>
    </r-row>

    <r-row class="px-4">
      <r-col :cols="{ widest: 4, wide: 4, middle: 6, narrow: 2 }">
        <div class="emergency-card__item">
          <div class="emergency-card__icon oldboy--bg">
            <r-icon fill="white" icon="emergency"/>
          </div>

          <div class="emergency-card__icon-desc mozzarella">
            {{ $t('services_contacts.emergency.police') }}
          </div>
        </div>
      </r-col>

      <r-col :cols="{ widest: 4, wide: 4, middle: 6, narrow: 2 }">
        <div class="emergency-card__item">
          <div class="emergency-card__icon oldboy--bg">
            <r-icon fill="white" icon="city-service"/>
          </div>

          <div class="emergency-card__icon-desc mozzarella">
            {{ $t('services_contacts.emergency.mchs') }}
          </div>
        </div>
      </r-col>

      <r-col :cols="{ widest: 4, wide: 4, middle: 6, narrow: 2 }">
        <div class="emergency-card__item">
          <div class="emergency-card__icon oldboy--bg">
            <r-icon fill="white" icon="gas-supply"/>
          </div>

          <div class="emergency-card__icon-desc mozzarella">
            {{ $t('services_contacts.emergency.gas') }}
          </div>
        </div>
      </r-col>

      <r-col :cols="{ widest: 4, wide: 4, middle: 6, narrow: 2 }">
        <div class="emergency-card__item">
          <div class="emergency-card__icon oldboy--bg">
            <r-icon fill="white" icon="medicine"/>
          </div>

          <div class="emergency-card__icon-desc mozzarella">
            {{ $t('services_contacts.emergency.ambulance') }}
          </div>
        </div>
      </r-col>

      <r-col :cols="{ widest: 4, wide: 4, middle: 6, narrow: 2 }">
        <div class="emergency-card__item">
          <div class="emergency-card__icon oldboy--bg">
            <r-icon fill="white" icon="new"/>
          </div>

          <div class="emergency-card__icon-desc mozzarella">
            {{ $t('services_contacts.emergency.firefighters') }}
          </div>
        </div>
      </r-col>

      <r-col :cols="{ widest: 4, wide: 4, middle: 6, narrow: 2 }">
        <div class="emergency-card__item">
          <div class="emergency-card__icon oldboy--bg">
            <r-icon fill="white" icon="partnership"/>
          </div>

          <div class="emergency-card__icon-desc mozzarella">
            {{ $t('services_contacts.emergency.psychology') }}
          </div>
        </div>
      </r-col>
    </r-row>
  </div>
</template>

<script>
export default {
  name: "EmergencyCard"
}
</script>

<style lang="scss">
.emergency-card {
  background: var(--rir-fargo);
  border-radius: 16px;
  padding: 24px 0;
  margin-top: 24px;
  margin-bottom: 32px;

  &__img {
    position: relative;

    img {
      position: absolute;
      top: -48px;
      right: 0;
      display: block;
      max-width: 134px;
      height: auto;
    }
  }

  &__title {
    color: white;
    margin-bottom: 12px;
  }

  &__desc {
    color: white;
    margin-bottom: 24px;
  }

  &__item {
    display: flex;
    align-items: center;
  }

  &__icon {
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    margin-right: 8px;
  }

  &__icon-desc {
    color: white;

  }
}
</style>
